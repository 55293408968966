<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="false" />
    <v-snackbar v-model="showSnackBar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showSnackBar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="appConfirmation" width="500" persistent>
      <v-card>
        <v-card-title class="headline">
          <span style="font-size:16px;font-weight:600">Would you like to delete this Blog ?</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#00a972" text @click="appConfirmation = false">Cancel</v-btn>
          <v-btn color="red" text @click="deleteBlog">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout wrap>
      <v-flex xs12 md12 pa-0>
        <v-card id="app_height" outlined tile>
          <v-layout wrap justify-space-around>
            <v-flex xs12 md12 pa-8>
              <v-layout wrap>
                <v-flex xs12 md12 text-center>
                  <span style="font-size:20px;font-weight:500;">Blogs</span>
                </v-flex>
              </v-layout>
              <v-layout wrap px-2 justify-center>
                <v-flex xs12 sm12 md12>
                  <v-card-title>
                    <v-layout wrap justify-space-around align="center">
                      <v-flex xs12 sm6 md3 pt-3>
                        <router-link to="/AddBlog">
                          <v-btn text color="#26af82">Add New Blog</v-btn>
                        </router-link>
                      </v-flex>
                      <v-flex xs12 sm6 md3>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          color="#26af82"
                          label="Search"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                  </v-card-title>
                  <v-data-table
                    :headers="headers"
                    :items="blog"
                    :items-per-page="itemsPerPage"
                    :search="search"
                    hide-default-footer
                    dense
                    :loading="appLoading"
                    loading-text="Loading... Please wait"
                    class="elevation-1"
                    color="#26af82"
                  >
                    <template v-slot:item.title="{ item }">
                      <span v-if="item.title.length>50">{{item.title.substring(0,50)+'...'}}</span>
                      <span v-else>{{item.title}}</span>
                    </template>
                    <template v-slot:item.draft="{ item }">
                      <v-layout>
                        <v-flex v-if="item.draft" text-center>
                          <v-btn
                            small
                            text
                            color="purple"
                            @click="changeMode(item)"
                            block
                            class="text-capitalize"
                          >Publish Post</v-btn>
                        </v-flex>
                        <v-flex v-else>
                          <v-btn
                            small
                            text
                            color="orange"
                            @click="changeMode(item)"
                            block
                            class="text-capitalize"
                          >Draft Post</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                    <template v-slot:item._id="{ item }">
                      <v-layout>
                        <v-flex>
                          <router-link :to="'/ViewBlog/'+item._id">
                          <v-btn small text color="green" block class="text-capitalize">View</v-btn>
                          </router-link>
                        </v-flex>
                        <v-flex>
                          <router-link :to="'/EditBlog/'+item._id">
                            <v-btn small text color="blue" block class="text-capitalize">Edit</v-btn>
                          </router-link>
                        </v-flex>
                        <v-flex>
                          <v-btn
                            @click="delModel(item)"
                            small
                            text
                            color="red"
                            block
                            class="text-capitalize"
                          >Delete</v-btn>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-data-table>

                  <v-layout wrap justify-center>
                    <v-flex xs12 sm12 md12 pt-2>
                      <v-card>
                        <v-layout pt-2 wrap style="align-items:center" justify-center>
                          <v-flex xs2 sm2 md2 text-right>
                            <span v-if="page>1">
                              <v-btn @click="prevpage" small text color="#26af82">Prev</v-btn>
                            </span>
                          </v-flex>
                          <v-flex xs2 sm2 md1 text-center>
                            <v-text-field
                              v-model="page"
                              color="#26af82"
                              min="1"
                              :dense="true"
                              :max="totalpages"
                              type="number"
                              class="centered-input"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs2 sm2 md2 text-left>
                            <span v-if="page<totalpages">
                              <v-btn @click="nextpage" small text color="#26af82">Next</v-btn>
                            </span>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap pt-2 style="align-items:center" justify-center>
                          <v-flex xs6 sm6 md2 text-left>Items Per Page</v-flex>
                          <v-flex xs6 sm6 md1>
                            <v-select
                              v-model="itemsPerPage"
                              :items="ItemsCount"
                              color="#26af82"
                              required
                            ></v-select>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import VueElementLoading from "vue-element-loading";
import axios from "axios";
export default {
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      appConfirmation: false,
      showSnackBar: false,
      timeout: 3000,
      msg: null,
      search: null,
      headers: [
        { text: "Title", align: "left", sortable: true, value: "title" },
        { text: "Author", align: "left", value: "author", sortable: true },
        { text: "Created Date", align: "center", value: "createddate" },
        { text: "Status", align: "center", value: "draft", sortable: true },
        { text: "Action", align: "center", value: "_id", sortable: false }
      ],
      blog: [],
      itemsPerPage: 50,
      page: 1,
      totalpages: 0,
      ItemsCount: [5, 10, 25, 50, 500],
      deleteId: null
    };
  },
  beforeMount() {
    this.getBlog();
  },
  watch: {
    itemsPerPage: function() {
      this.getBlog();
    },
    page: function() {
      if (this.page > 0) this.getBlog();
    }
  },
  methods: {
    prevpage() {
      if (this.page > 1) this.page--;
    },
    nextpage() {
      if (this.page < this.totalpages) this.page++;
    },
    getBlog() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/blog/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        params: {
          count: this.itemsPerPage,
          page: this.page
        }
      })
        .then(response => {
          this.appLoading = false;
          this.blog = response.data.data;
          for (var i = 0; i < this.blog.length; i++) {
            var dt = new Date(this.blog[i].createddate);
            dt = dt.toString();
            dt = dt.slice(4, 24);
            this.blog[i].createddate = dt;
          }
          // this.totalpages = response.data.pages;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showSnackBar = true;
        });
    },
    delModel(event) {
      this.deleteId = event._id;
      this.appConfirmation = true;
    },
    deleteBlog() {
      this.appConfirmation = false;
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/blog/remove",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: {
          id: this.deleteId
        }
      })
        .then(response => {
          this.appLoading = false;
          this.getBlog();
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    },
    changeMode(item) {
      this.appLoading = true;
      var data = {};
      data["id"] = item._id;
      if (item.draft == true) {
        data["draft"] = false;
      } else {
        data["draft"] = true;
      }
      axios({
        method: "POST",
        url: "/blog/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token")
        },
        data: data
      })
        .then(response => {
          this.appLoading = false;
          this.getBlog();
          this.msg = response.data.msg;
          this.showSnackBar = true;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.showSnackBar = true;
        });
    }
  }
};
</script>
<style>
@media only screen and (min-width: 800px) {
  #app_height {
    min-height: 80vh;
    min-width: 80vw;
  }
}
.centered-input input {
  text-align: center;
  height: 20px;
}
</style>